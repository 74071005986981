function Review() {
  return (
    <section className="review">
      <div className="review__container">
        <iframe src="https://yandex.ru/maps-reviews-widget/1327614716?comments"  className="review__iframe"/>
        <a href="https://yandex.ru/maps/org/vega_otel/1327614716/" className="review__link" target="_blank" >Вега Отель на карте Наро‑Фоминска — Яндекс Карты</a>
      </div>
    </section>
  )
}

export default Review;